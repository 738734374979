.replier1 {
  background-color: lightblue;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.replier2 {
  background-color: lightgreen;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}